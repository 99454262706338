import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from "baseui/modal";
import { RiChargingPileFill } from "react-icons/ri";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import { AiFillStar } from "react-icons/ai";
import { BsShieldFillCheck, BsCheckCircleFill } from "react-icons/bs";
import { DriverMsg } from "../Components/AllImportFiles/ImportSvg";
import "./TrackPage.css";
import axios from "axios";
import lottie from "lottie-web";
import PendingPickNowLottie from "../Assets/lottie/PendingPickNowLottie.json";
import ConfirmDriverLottie from "../Assets/lottie/ConfirmDriverLottie.json";
import DoneTripLottie from "../Assets/lottie/DoneTripLottie.json";
import DriverFAQs from "./DriverFAQs";
import FAQsComponent from "./FAQsComponent";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { ApplicationContext } from "../context/ApplicationContextProvider";
import { useCookies } from "react-cookie";
import LoadingSpinner from "../Uis/Spinner/Spinner";
import { Cash } from "../Components/AllImportFiles/ImportPng";
import clevertap from "clevertap-web-sdk";
import { BookingFlowDataContext } from "../context/BookingFlowDataContextProvider";
import MyGateDriverDetails from "../OrderPage/DriverDetails/MyGateDriverDetails";
import { BookingFlowParaContext } from "../context/BookingFlowParaProvider";
import MyGatePaymentDetails from "../OrderPage/PaymentDetails/MyGatePaymentDetails";
import MyGateLoader from "../Uis/MyGateLoader/MyGateLoader";
import toast from "react-hot-toast";
import CarWashAppLoader from "../Uis/CarWashAppLoader/CarWashAppLoader";
import {
  bookingCancelledObj,
  trackBookingScreenObj,
} from "../utils/EventObject";
import RescheduleBlock from "../OrderPage/RescheduleBlock/RescheduleBlock";
import CarWashVideo from "./CarWashVideo/CarWashVideo";


function TrackingPage() {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const [mapData, setMapData] = useState([]);
  const [myGateStatusLoader, setMyGateStatusLoader] = useState(true);
  const [isCancelModal, setIsCancelModal] = React.useState(false);
  const pendingLoader = useRef(null);
  const confirmLoader = useRef(null);
  const tripEndLoader = useRef(null);
  const initialLoad = useRef(true);
  const [showErrMsgCancel, setShowErrMsgCancel] = React.useState("");
  const [showCancelLoader, setShowCancelLoader] = React.useState(false);
  const [showReschedulePopUp, setShowReschedulePopUp] = useState(false);
  const [cookiesUser] = useCookies();
  const [value, setValue] = React.useState("");
  const mapRef = useRef(null);
  const {
    myGateBookingID,
    setConsentFlag,
    myGateWashToken,
    showMyGateDriverDetailsPopUp,
    setShowMyGateDriverDetailsPopUp,
    appCarWashTrackSrc,
    appUserID,
    setEvChargingDetails,
  } = useContext(ApplicationContext);
  const { trackDriverScreen, sendingData } = useContext(BookingFlowDataContext);
  const { setShowPaymentDetails, showPaymentDetails } = useContext(
    BookingFlowParaContext
  );
  const [optionBookingTrack, setOptionBookingTrack] = useState(false);
  const backBtnStyle = {
    marginTop: "0.5rem",
    height: "30px",
    width: "30px",
    backgroundColor: "#FFFFFF",
    color: "#F93356",
    marginLeft: "0.5rem",
    marginBottom: "0.3rem",
  };
  function rad2degr(rad) {
    return (rad * 180) / Math.PI;
  }
  function degr2rad(degr) {
    return (degr * Math.PI) / 180;
  }
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: pendingLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: PendingPickNowLottie,
    });
    return () => anim.destroy();
  }, [mapData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: confirmLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: ConfirmDriverLottie,
    });
    return () => anim.destroy();
  }, [mapData]);
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: tripEndLoader.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: DoneTripLottie,
    });
    setShowMyGateDriverDetailsPopUp(true);
    return () => anim.destroy();
  }, [mapData]);
  const mapIconStatus = {
    status: "",
  };



  useEffect(() => {
    let interval;
    const handleFetchBookingDetails = () => {
      const origin = [];
      const destination = [];
      const evChargingDestination = [];
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${myGateBookingID}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            
            origin.push(res.data.driver_details.driver_longitude);
            origin.push(res.data.driver_details.driver_latitude);
            destination.push(res.data.pickup_details.pickup_longitude);
            destination.push(res.data.pickup_details.pickup_latitude);
            if (
              (res.data.booking_details.booking_type === "ev_charging" && res.data.charging_station_details &&
                Object.keys(res.data.charging_station_details).length !== 0) &&
                (res.data.booking_details.booking_status === "driver_assigned" ||
                res.data.booking_details.booking_status === "driver_on_way" ||
                res.data.booking_details.booking_status === "running")
              
            ) {
              evChargingDestination.push(
                res.data.charging_station_details.longitude
              );
              evChargingDestination.push(
                res.data.charging_station_details.latitude
              );
            }
            setMapData(res.data);
            setEvChargingDetails(res.data);
            setMyGateStatusLoader(false);
            mapIconStatus.status = res.data.booking_details.booking_status;

            trackBookingScreenObj["Service Name"] =
              res.data.is_wash_booking === true ? "car_wash" : "driver_service";
            trackBookingScreenObj.source = trackDriverScreen;
            trackBookingScreenObj.bookingid = myGateBookingID;
            trackBookingScreenObj.booking_status =
              res.data.booking_details.booking_status;
            trackBookingScreenObj.client = "mygate";
            clevertap.event.push(
              "booking_tracking_screen_opened",
              trackBookingScreenObj
            );

            const bookingStatus = [
              "pending",
              "confirmed",
              "driver_assigned",
              "driver_on_way",
              "running",
            ];
            if (
              bookingStatus.includes(res.data.booking_details.booking_status)
            ) {
              interval = setTimeout(handleFetchBookingDetails, initialLoad.current ? 100 : 30000);
              initialLoad.current = false;

            }

            const _coordinatesLatLng = [origin, destination];

            if (evChargingDestination.length !== 0) {
              _coordinatesLatLng.push(evChargingDestination);
            }
            gettingCoordinate(_coordinatesLatLng, res.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    if (!myGateBookingID === true) {
      console.log(null);
    } else {
      handleFetchBookingDetails();
    }
    return () => clearTimeout(interval);
  }, [myGateBookingID]);







  function gettingCoordinate(latLngInDegr, res) {
    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
      const lat = degr2rad(latLngInDegr[i][LATIDX]);
      const lng = degr2rad(latLngInDegr[i][LNGIDX]);
      // sum of cartesian coordinates
      sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longitude
    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);

    showMapBox([rad2degr(lat), rad2degr(lng)], latLngInDegr, res);
  }

  async function showMapBox(centreCoordinate, latLngInDegr, response) {
    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          user_type: "customer",
          properties: {
            icon:
              mapIconStatus.status === "running" ||
              sendingData.booking_type === "roundtrip"
                ? "https://ik.imagekit.io/driveu/drop_big%20(1)_wUwv30e5Au.png?updatedAt=1732185238796"
                : "https://ik.imagekit.io/driveu/location_big%20(1)_tme6O2LD8q.png?updatedAt=1732185238592",
            iconSize: [25, 25],
          },
          geometry: {
            type: "Point",
            coordinates: latLngInDegr[1], // Customer coordinates [lng, lat]
          },
        },
        {
          type: "Feature",
          user_type: "driver",
          properties: {
            icon:
              mapIconStatus.status === "running"
                ? "https://ik.imagekit.io/9xamykm1snt/car_R3pzmyBE41.png?updatedAt=1680606485280"
                : "https://ik.imagekit.io/9xamykm1snt/driver_TjlTRZc7t.png?updatedAt=1680606485283",
            iconSize: [25, 25],
          },
          geometry: {
            type: "Point",
            coordinates: latLngInDegr[0], // Driver coordinates [lng, lat]
          },
        },
        ...(response.booking_details?.booking_type === "ev_charging" &&
        response.charging_station_details &&
        Object.keys(response.charging_station_details).length !== 0 &&
        latLngInDegr[2]
          ? [
              {
                data: "not Required",
                type: "Feature",
                user_type: "thirdPoint",
                properties: {
                  icon: "https://ik.imagekit.io/driveu/v3_gl5NDxJ9p3.png?updatedAt=1729755446057",
                  iconSize: [25, 25],
                  additionalProperty: "value", // Replace with the actual property you want to add
                },
                geometry: {
                  type: "Point",
                  coordinates: latLngInDegr[2], // Third point coordinates [lng, lat]
                },
              },
            ]
          : []),
      ],
    };

    let map = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: centreCoordinate,
      zoom: 9,
      attributionControl: false,
    });

    const bounds = new mapboxgl.LngLatBounds();

    // Add markers to the map
    for (const marker of geojson.features) {
      const el = document.createElement("div");
      el.style.width = `${marker.properties.iconSize[0]}px`;
      el.style.height = `${marker.properties.iconSize[1]}px`;
      el.className = "marker";
      el.style.backgroundImage = `url("${marker.properties.icon}")`;
      el.style.backgroundSize = "100%";
      el.style.borderRadius = "50%";

      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);

      bounds.extend(marker.geometry.coordinates);
    }

    map.fitBounds(bounds, { padding: 20 });

    // Fetch the real driving routes using Mapbox Directions API

    const route1 = await getDirections(latLngInDegr[0], latLngInDegr[1]);
    const route2 =
      response.booking_details.booking_type === "ev_charging" &&
      response.charging_station_details &&
      Object.keys(response.charging_station_details).length !== 0 &&
      (await getDirections(latLngInDegr[1], latLngInDegr[2]));

    // Check if route1 exists and if booking status and charging status are as per the conditions

    if (
      (response.booking_details.booking_status === "running" && response.charging_station_details &&
        response.charging_station_details.is_charging_completed === true) ||
      route1
    ) {
      // Add first route (green) - driver's route
      map.addSource("route1", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: route1,
          },
        },
      });

      map.addLayer({
        id: "route1",
        type: "line",
        source: "route1",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "green", // driver's route is green
          "line-width": 4,
        },
      });
    }

    if (route2) {
      // Add second route (red or grey) - customer's route
      map.addSource("route2", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: route2,
          },
        },
      });

      map.addLayer({
        id: "route2",
        type: "line",
        source: "route2",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          // customer's route will be green if the charging is still in progress, else grey
          "line-color":
            response.booking_details.booking_status === "running" && response.charging_station_details &&
            response.charging_station_details.is_charging_completed === false
              ? "green"
              : "grey",
          "line-width": 4,
        },
      });
    }

    // If both routes are displayed, ensure route1 (green) is on top by adding it last.
    if (route1) {
      map.moveLayer("route1"); // This will move the green route (driver's route) on top of route2
    }
  }

  // Function to fetch directions from Mapbox Directions API
  async function getDirections(start, end) {

    if (
      Array.isArray(start) &&
      Array.isArray(end) &&
      start.length === 2 &&
      end.length === 2 &&
      !(start[0] === 0 && start[1] === 0) &&
      !(end[0] === 0 && end[1] === 0)
    ) {
      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&access_token=${mapboxgl.accessToken}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.routes && data.routes.length > 0) {

          return data.routes[0].geometry.coordinates;
        } else {
          console.error("No route found");
          return null;
        }
      } catch (error) {
        console.error("Error fetching directions:", error);
        return null;
      }
    }
  }

  
  // map box

  console.log(process.env.REACT_APP_PROD_API)
  const handleFetchBookingDetails = () => {
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${myGateBookingID}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setMapData(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleCancelBooking = () => {
    setIsCancelModal((s) => !s);
  };
  const handleCloseMWeb = () => {
    setConsentFlag(false);
    // setCookie("userid", "", { path: "/" });
    window.open("mygate://close", "_self");
  };
  const errMsgStyle = {
    fontFamily: "Circular-Std-Book",
    height: "26px",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    color: "red",
  };
  const RadioOption = {
    fontSize: "15px",
  };
  const radioOverrides = {
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        backgroundColor:
          myGateBookingID &&
          (appCarWashTrackSrc === "wash" ||
            appCarWashTrackSrc === "driver" ||
            appCarWashTrackSrc === "ev")
            ? "#127e40"
            : "#f93356",
      }),
    },
    Root: {
      style: ({ $theme }) => ({
        display: "flex",
        alignItems: "center",
        width: "250px",
      }),
    },
  };
  const handleCancelBookingWay = (e) => {
    mapData.cancellation_reason = e.currentTarget.value;
    setValue(e.currentTarget.value);
  };
  const handleCancelModal = () => {
    setIsCancelModal(false);
    setValue("");
  };
  const handleCancelApiCall = () => {
    if (value === "") {
      setShowErrMsgCancel("Please Select a Cancellation Reason");
      setTimeout(() => {
        setShowErrMsgCancel("");
      }, 3000);
    } else {
      setShowCancelLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL_HULK,
        url:
          mapData.booking_details.booking_type === "ev_charging"
            ? `${process.env.REACT_APP_PROD_API}/web/booking/cancel/?user_id=${appUserID}`
            : `${BASE_URL_HULK}/app/cancel_booking/?user_id=${cookiesUser.userid}`,
        data: { booking_id: myGateBookingID, cancellation_reason: value },
        withCredentials: false,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            bookingCancelledObj["Service Name"] = "driver_service";
            bookingCancelledObj.bookingid = mapData.booking_details.booking_id;
            bookingCancelledObj.booking_status =
              mapData.booking_details.booking_status;
            bookingCancelledObj.cancellation_reason = value;
            bookingCancelledObj.source = trackDriverScreen;
            bookingCancelledObj.client = "mygate";
            clevertap.event.push("booking_cancelled", bookingCancelledObj);
            setShowCancelLoader(false);
            handleCancelModal();
            (function handleFetchBookingDetails() {
              axios({
                method: "GET",
                baseURL: BASE_URL_HULK,
                url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${myGateBookingID}`,
                withCredentials: false,
                headers: {
                  "Content-Type": `application/json`,
                },
              })
                .then((res) => {
                  if (res.status === 200) {
                    setMapData(res.data);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                });
            })();
          } else if (data.status === "error") {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          setShowCancelLoader(false);
          console.log(err.message);
        });
    }
  };
  const handleCancelWashApiCall = () => {
    if (value === "") {
      setShowErrMsgCancel("Please Select a Cancellation Reason");
      setTimeout(() => {
        setShowErrMsgCancel("");
      }, 3000);
    } else {
      setShowCancelLoader(true);
      axios({
        method: "POST",
        baseURL: BASE_URL,
        url:
          (appCarWashTrackSrc === "wash" ||
            appCarWashTrackSrc === "driver" ||
            appCarWashTrackSrc === "ev") &&
          appUserID
            ? `${BASE_URL}/cancel-wash-booking/?user_id=${appUserID}`
            : `${BASE_URL}/cancel-wash-booking/?my_gate_session_id=${myGateWashToken}`,
        data: { booking_id: myGateBookingID, cancellation_reason: value },
        withCredentials: false,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            bookingCancelledObj["Service Name"] = "car_wash";
            bookingCancelledObj.bookingid = mapData.booking_details.booking_id;
            bookingCancelledObj.booking_status =
              mapData.booking_details.booking_status;
            bookingCancelledObj.cancellation_reason = value;
            bookingCancelledObj.source = trackDriverScreen;
            bookingCancelledObj.client = "mygate";
            clevertap.event.push("booking_cancelled", bookingCancelledObj);
            setShowCancelLoader(false);
            handleCancelModal();
            (function handleFetchBookingDetails() {
              axios({
                method: "GET",
                baseURL: BASE_URL_HULK,
                url: `${BASE_URL_HULK}/app/booking-info-v2/?booking_id=${myGateBookingID}`,
                withCredentials: false,
                headers: {
                  "Content-Type": `application/json`,
                },
              })
                .then((res) => {
                  if (res.status === 200) {
                    setMapData(res.data);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                });
            })();
          } else if (data.status === "error") {
            toast.error(data.message);
          }
        })
        .catch((err) => {
          setShowCancelLoader(false);
          console.log(err.message);
        });
    }
  };
  React.useEffect(() => {
    if (showMyGateDriverDetailsPopUp) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showMyGateDriverDetailsPopUp]);
  return (
    <React.Fragment>
      <>
        {(myGateBookingID && appCarWashTrackSrc === "wash") ||
        appCarWashTrackSrc === "ev" ? null : (
          <div
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              zIndex: "1000",
            }}
          >
            <BsFillArrowLeftCircleFill
              onClick={handleCloseMWeb}
              style={backBtnStyle}
            />
          </div>
        )}
      </>
      <div className="mapbox-map">
        <div className="mapContainer">
          {mapData.length === 0 ? (
            <img
              src="https://ik.imagekit.io/driveu/Map_Loader_0o_KoGrj9P.png?updatedAt=1679661660741"
              width="100%"
              height="100%"
              alt="Loader Map"
            />
          ) : mapData.booking_details.booking_status === "pending" &&
            mapData.is_picknow === true ? (
            <div
              id="loader_lottie"
              ref={pendingLoader}
              style={{ width: "100%", height: "100%" }}
            />
          ) : mapData.booking_details.booking_status === "pending" &&
            mapData.is_picknow === false ? (
            <div
              id="loader_lottie"
              ref={confirmLoader}
              style={{ width: "100%", height: "100%" }}
            />
          ) : mapData.booking_details.booking_status === "confirmed" ? (
            <div
              id="loader_lottie"
              ref={confirmLoader}
              style={{ width: "100%", height: "100%" }}
            />
          ) : mapData.booking_details.booking_status === "done" ? (
            <div
              id="loader_lottie"
              ref={tripEndLoader}
              style={{ width: "100%", height: "100%" }}
            />
          ) : mapData.booking_details.booking_status === "cancelled" ? (
            <h2>Your booking has been cancelled.</h2>
          ) : null}

          <div ref={mapRef} className="mapShower">
            {mapData.length === 0 ? null : mapData.booking_details
                .booking_status === "running" ||
              mapData.booking_details.booking_status === "driver_assigned" ||
              mapData.booking_details.booking_status === "driver_on_way" ? (
              <div
                style={{
                  height: "45vh",
                  width: "100vw",
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {myGateStatusLoader &&
        (appCarWashTrackSrc === "wash" ||
          appCarWashTrackSrc === "driver" ||
          appCarWashTrackSrc === "ev") ? (
          <CarWashAppLoader />
        ) : myGateStatusLoader ? (
          <MyGateLoader />
        ) : (
          <div
            className="locationDetails"
            style={{
              backgroundColor:
                mapData.length === 0
                  ? null
                  : mapData.booking_details.booking_status === "cancelled"
                  ? "white"
                  : myGateBookingID &&
                    (appCarWashTrackSrc === "wash" ||
                      appCarWashTrackSrc === "driver" ||
                      appCarWashTrackSrc === "ev")
                  ? "#127e40"
                  : "#f93356",
            }}
          >
            <>
              {mapData.length === 0 ? null : mapData.booking_details
                  .booking_status === "cancelled" ? null : (
                <div
                  className="bookDriverMsg"
                  style={{
                    backgroundColor:
                      mapData.length === 0
                        ? null
                        : myGateBookingID &&
                          (appCarWashTrackSrc === "wash" ||
                            appCarWashTrackSrc === "driver" ||
                            appCarWashTrackSrc === "ev")
                        ? "#127e40"
                        : "#f93356",
                  }}
                >
                  <div className="msgPopUpDiv">
                    <DriverMsg />
                  </div>
                  <span className="livePopUpMsg">
                    {mapData.length === 0
                      ? null
                      : mapData.booking_status_message}
                  </span>
                </div>
              )}
            </>

            <div className="locationWrapper">
              <div className="bookDriverDetails">
                <div style={{ width: "98%", margin: "auto" }}>
                  {mapData.length === 0
                    ? null
                    : `Booking ID: ${mapData.booking_details.booking_id}`}
                </div>
                <>
                  {mapData.length === 0 ? null : mapData.booking_details
                      .booking_status === "done" ||
                    mapData.booking_details.booking_status ===
                      "driver_assigned" ||
                    mapData.booking_details.booking_status ===
                      "driver_on_way" ||
                    mapData.booking_details.booking_status === "running" ? (
                    <div className="DriverDetailsContainer">
                      <div className="DriverImageWrapper">
                        <div style={{ width: "40px", height: "40px" }}>
                          <img
                            src={mapData.driver_details.photo_url}
                            alt={mapData.driver_details.driver_name}
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div
                          style={{
                            color: "#09081a",
                            fontSize: "14px",
                            fontWeight: 700,
                            marginBottom: "5px",
                            objectFit: "container",
                          }}
                        >
                          {mapData.driver_details.driver_name}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>Rating:</span>
                          &nbsp;
                          <>{mapData.driver_details.driver_rating}</>
                          <AiFillStar style={{ color: "#127e40" }} />
                        </div>
                        <button
                          // className="CallDriver"
                          className={
                            mapData.booking_details.booking_status === "done" &&
                            mapData.is_wash_booking === false
                              ? "CallDriver"
                              : "call_details"
                          }
                          style={{
                            backgroundColor:
                              mapData.length === 0
                                ? null
                                : myGateBookingID &&
                                  (appCarWashTrackSrc === "wash" ||
                                    appCarWashTrackSrc === "driver" ||
                                    appCarWashTrackSrc === "ev")
                                ? "#127e40"
                                : "#f93356",
                          }}
                        >
                          {mapData.length === 0 ? null : mapData.booking_details
                              .booking_status === "done" &&
                            mapData.is_wash_booking === false ? (
                            <div
                              onClick={() => {
                                setShowMyGateDriverDetailsPopUp(true);
                                setShowPaymentDetails(false);
                              }}
                              className="show_details"
                            >
                              Show Details
                            </div>
                          ) : (
                            <a
                              href={`tel:${mapData.driver_details.contact_number}`}
                              style={{
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#ffffff",
                              }}
                            >
                              <MdCall />
                            </a>
                          )}
                        </button>
                      </div>
                      <div className="DriverDetailsWrapper">
                        <div className="TripBtn">
                          <div
                            className="oneWayTrip"
                            // style={{ width: "45%", border: "2px solid red" }}
                          >
                            {mapData.length === 0 ? null : mapData
                                .booking_details.category === "local" ? (
                              "Oneway Trip"
                            ) : mapData.booking_details.category ===
                              "package" ? (
                              "Round Trip"
                            ) : mapData.booking_details.category ===
                              "outstation" ? (
                              "Outstation"
                            ) : mapData.booking_details.car_care_products
                                .length === 0 ? (
                              mapData.booking_details.booking_type_display
                            ) : (
                              <div className="washQuantityWrapper">
                                {mapData.booking_details.car_care_products.map(
                                  (elem, index) => {
                                    return (
                                      <div className="washQuantity" key={index}>
                                        <div>{elem.name}</div>
                                        <div className="quantityBlock">
                                          <span>x</span>
                                          <span>{elem.quantity}</span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : mapData.booking_details.booking_status === "cancelled" ? (
                    <div className="bookingFirstBlock">
                      <div className="bookScheduledMsg">Booking Cancelled</div>
                      {/* <div className="date-scheduled">
                    Booking ID: {mapData.booking_details.booking_id}
                  </div> */}
                      <div className="bookTypeApp">
                        <div className="oneWayTrip">
                          {mapData.length === 0 ? null : mapData.booking_details
                              .category === "local" ? (
                            "Oneway Trip"
                          ) : mapData.booking_details.category === "package" ? (
                            "Round Trip"
                          ) : mapData.booking_details.category ===
                            "outstation" ? (
                            "Outstation"
                          ) : mapData.booking_details.car_care_products
                              .length === 0 ? null : (
                            <div className="washQuantityWrapper">
                              {mapData.booking_details.car_care_products.map(
                                (elem, index) => {
                                  return (
                                    <div className="washQuantity" key={index}>
                                      <div>{elem.name}</div>
                                      <div className="quantityBlock">
                                        <span>x</span>
                                        <span>{elem.quantity}</span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="bookingFirstBlock">
                      <div className="bookScheduledMsg">
                        <span>Booking Scheduled</span>
                        <span className="tickMark">
                          <BsCheckCircleFill />
                        </span>
                      </div>
                      <div className="date-scheduled">
                        <span>
                          {mapData.length === 0
                            ? null
                            : mapData.pickup_details.datetime_string}
                        </span>
                      </div>
                      <div className="bookTypeApp">
                        <div className="oneWayTrip">
                          {mapData.length === 0 ? null : mapData.booking_details
                              .category === "local" ? (
                            "Oneway Trip"
                          ) : mapData.booking_details.category === "package" ? (
                            "Round Trip"
                          ) : mapData.booking_details.category ===
                            "outstation" ? (
                            "Outstation"
                          ) : mapData.booking_details.car_care_products
                              .length === 0 ? null : (
                            <div className="washQuantityWrapper">
                              {mapData.booking_details.car_care_products.map(
                                (elem, index) => {
                                  return (
                                    <div className="washQuantity" key={index}>
                                      <div>{elem.name}</div>
                                      <div className="quantityBlock">
                                        <span>x</span>
                                        <span>{elem.quantity}</span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                        {/* <div className="modifyBooking">
                      Booking ID: {mapData.booking_details.booking_id}
                    </div> */}
                      </div>
                    </div>
                  )}
                </>
                <div className="locationFirstBlock">
                  <div className="pickUpDropBlock">
                    {mapData.length === 0 ? null : mapData.booking_details
                        .category === "local" ? (
                      <Fragment>
                        <div className="locationDot">
                          <IoLocationSharp
                            style={{
                              color: "green",
                              width: "24px",
                              height: "24px",
                            }}
                          />
                          <div className="location-dotted-line" />
                          <IoLocationSharp
                            style={{
                              color: "red",
                              width: "24px",
                              height: "24px",
                            }}
                          />
                        </div>
                        <div className="locationWrapperBlock">
                          <div className="pickupBlock">
                            <div className="pickup-Place">
                              Your Pickup Location
                            </div>
                            <div className="pickup-place-first">
                              {mapData.length === 0
                                ? null
                                : mapData.pickup_details.pickup_address}
                            </div>
                          </div>

                          <div className="pickupBlock">
                            <div className="drop-location">
                              Your Drop Location
                            </div>
                            <div className="drop-place">
                              {mapData.length === 0
                                ? null
                                : mapData.drop_details.drop_address}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : mapData.booking_details.category === "package" ? (
                      <Fragment>
                        <div className="locationDot">
                          <IoLocationSharp style={{ color: "green" }} />
                        </div>
                        <div className="locationWrapperBlock">
                          <div className="pickupBlock">
                            <div className="pickup-Place">
                              Your Pickup & Drop Location
                            </div>
                            <div className="pickup-place-first">
                              {mapData.length === 0
                                ? null
                                : mapData.pickup_details.pickup_address}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : mapData.booking_details.category === "" ? (
                      <Fragment>
                        <div className="locationDot">
                          <IoLocationSharp style={{ color: "green" }} />
                        </div>
                        <div className="locationWrapperBlock">
                          <div className="pickupBlock">
                            <div className="pickup-Place">Your Location</div>
                            <div className="pickup-place-first">
                              {mapData.length === 0
                                ? null
                                : mapData.pickup_details.pickup_address}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                  </div>
                </div>
                <>
                  {mapData.length === 0 ? null : mapData.is_insured ===
                    false ? null : (
                    <div className="InsuranceContainer">
                      <div className="InsuranceTitle">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <BsShieldFillCheck />
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "black",
                            fontWeight: "700",
                          }}
                        >
                          Opted for Secured Drive
                        </div>
                      </div>

                      <div className="InsuranceText">
                        <div style={{ width: "8%" }} />
                        <div
                          style={{
                            width: "92%",
                            fontSize: "13px",
                            color: "black",
                            opacity: "0.6",
                          }}
                        >
                          Trip policy is generated. The coverage will begin when
                          the trip starts.
                        </div>
                      </div>
                    </div>
                  )}
                </>
                <>
                    
                    
                  {
                    
                    // mapData.length !== 0 &&
                    mapData.booking_details.booking_type === "ev_charging" && mapData?.charging_station_details &&
                        Object.keys(mapData?.charging_station_details).length !==  0 &&
                        <>
                          <div className="InsuranceContainer">
                            <div className="InsuranceTitle">
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              >
                                <RiChargingPileFill color="rgb(18, 126, 64)" />
                              </div>
                              <div
                                style={{
                                  fontSize: "16px",
                                  color: "black",
                                  fontWeight: "700",
                                }}
                              >
                                Charging Station
                              </div>
                            </div>

                            {mapData?.charging_station_details?.name &&
                              mapData?.charging_station_details?.address && (
                                <div className="InsuranceText">
                                  <div style={{ width: "8%" }} />
                                  <div
                                    style={{
                                      width: "92%",
                                      fontSize: "13px",
                                      color: "black",
                                      opacity: "0.6",
                                    }}
                                  >
                                    {`${mapData.charging_station_details.name}, ${mapData.charging_station_details.address}`}
                                  </div>
                                </div>
                              )}
                          </div>
                        </>
                      
                  }
                </>
                <>
                  {mapData.length === 0 ? null : mapData.is_wash_booking ===
                      true ||
                    mapData.booking_details.booking_status ===
                      "cancelled" ? null : (
                    <>
                      <div className="InsuranceContainer">
                        <div className="InsuranceTitle">
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <img
                              src={Cash}
                              alt="Cash"
                              width="100%"
                              height="100%"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: "700",
                            }}
                          >
                            Pay after Trip Ends
                          </div>
                        </div>

                        <div className="InsuranceText">
                          <div style={{ width: "8%" }} />
                          <div
                            style={{
                              width: "92%",
                              fontSize: "13px",
                              color: "black",
                              opacity: "0.6",
                            }}
                          >
                            Please pay your driver in UPI or Cash once your trip
                            ends. The fare will be displayed on your driver's
                            device
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
                <>
                  {mapData.length === 0 ? null : mapData.booking_details
                      .booking_status === "cancelled" ||
                    mapData.booking_details.booking_status === "done" ||
                    mapData.booking_details.booking_status ===
                      "running" ? null : (mapData.booking_details
                      .booking_type === "ev_charging" &&
                      (mapData.booking_details.booking_status === "pending" ||
                        mapData.booking_details.booking_status ===
                          "confirmed")) ||
                    mapData.booking_details.booking_status ===
                      "driver_on_way" ||
                    mapData.booking_details.booking_status ===
                      "driver_assigned" ? (
                    <div className="HelpModifyContainer">
                      {
                        <div className="ModifyButtonContainer">
                          {/* <button className="GetSupportBtn">Get Support</button> */}
                          {mapData.length === 0 ? null : mapData.booking_details
                              .booking_status === "cancelled" ? (
                            <button
                              className="ModifyBtn"
                              style={{
                                visibility: "hidden",
                                backgroundColor:
                                  mapData.length === 0
                                    ? null
                                    : myGateBookingID &&
                                      (appCarWashTrackSrc === "wash" ||
                                        appCarWashTrackSrc === "driver" ||
                                        appCarWashTrackSrc === "ev")
                                    ? "#127e40"
                                    : "#f93356",
                              }}
                            >
                              Cancel Booking
                            </button>
                          ) : (
                            <button
                              className="ModifyBtn"
                              style={{
                                backgroundColor:
                                  mapData.length === 0
                                    ? null
                                    : myGateBookingID &&
                                      (appCarWashTrackSrc === "wash" ||
                                        appCarWashTrackSrc === "driver" ||
                                        appCarWashTrackSrc === "ev")
                                    ? "#127e40"
                                    : "#f93356",
                              }}
                              onClick={handleCancelBooking}
                            >
                              Cancel Booking
                            </button>
                          )}

                          <Modal
                            onClose={handleCancelModal}
                            isOpen={isCancelModal}
                          >
                            <FocusOnce>
                              <ModalHeader>Cancel Booking?</ModalHeader>
                            </FocusOnce>
                            <ModalBody>
                              <div style={errMsgStyle}>{showErrMsgCancel}</div>
                              <div className="RadioGroupBlock">
                                {mapData.length === 0 ? null : (
                                  <>
                                    {mapData.cancellation_reasons.map(
                                      (elem, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{ width: "40%" }}
                                          >
                                            <RadioGroup
                                              value={value}
                                              onChange={handleCancelBookingWay}
                                              name="CancelOrder"
                                              align={ALIGN.vertical}
                                            >
                                              <Radio
                                                value={elem.slug}
                                                overrides={radioOverrides}
                                              >
                                                <div style={RadioOption}>
                                                  {elem.title}
                                                </div>
                                              </Radio>
                                            </RadioGroup>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <ModalButton
                                kind="tertiary"
                                onClick={handleCancelModal}
                              >
                                Cancel
                              </ModalButton>
                              {showCancelLoader ? (
                                <ModalButton
                                  autoFocus
                                  style={{
                                    backgroundColor:
                                      myGateBookingID &&
                                      (appCarWashTrackSrc === "wash" ||
                                        appCarWashTrackSrc === "driver" ||
                                        appCarWashTrackSrc === "ev")
                                        ? "#127e40"
                                        : "#f93356",
                                  }}
                                >
                                  <LoadingSpinner />
                                </ModalButton>
                              ) : mapData.is_wash_booking === true ? (
                                <ModalButton
                                  autoFocus
                                  onClick={handleCancelWashApiCall}
                                  style={{
                                    backgroundColor:
                                      myGateBookingID &&
                                      (appCarWashTrackSrc === "wash" ||
                                        appCarWashTrackSrc === "driver" ||
                                        appCarWashTrackSrc === "ev")
                                        ? "#127e40"
                                        : "#f93356",
                                  }}
                                >
                                  Confirm
                                </ModalButton>
                              ) : (
                                <ModalButton
                                  autoFocus
                                  onClick={handleCancelApiCall}
                                  style={{
                                    backgroundColor:
                                      myGateBookingID &&
                                      (appCarWashTrackSrc === "wash" ||
                                        appCarWashTrackSrc === "driver" ||
                                        appCarWashTrackSrc === "ev")
                                        ? "#127e40"
                                        : "#f93356",
                                  }}
                                >
                                  Confirm
                                </ModalButton>
                              )}
                              {/* <ModalButton autoFocus onClick={handleCancelApiCall}>
                         Confirm
                       </ModalButton> */}
                            </ModalFooter>
                          </Modal>
                        </div>
                      }
                    </div>
                  ) : (
                    <div className="HelpModifyContainer">
                      <div className="ModifyButtonContainer">
                        {/* <button className="GetSupportBtn">Get Support</button> */}
                        {mapData.length === 0 ? null : mapData.booking_details
                            .booking_status === "cancelled" ? (
                          <button
                            className="ModifyBtn"
                            style={{
                              visibility: "hidden",
                              backgroundColor:
                                mapData.length === 0
                                  ? null
                                  : myGateBookingID &&
                                    (appCarWashTrackSrc === "wash" ||
                                      appCarWashTrackSrc === "driver" ||
                                      appCarWashTrackSrc === "ev")
                                  ? "#127e40"
                                  : "#f93356",
                            }}
                          >
                            Modify Booking
                          </button>
                        ) : (
                          <button
                            className="ModifyBtn"
                            style={{
                              backgroundColor:
                                mapData.length === 0
                                  ? null
                                  : myGateBookingID &&
                                    (appCarWashTrackSrc === "wash" ||
                                      appCarWashTrackSrc === "driver" ||
                                      appCarWashTrackSrc === "ev")
                                  ? "#127e40"
                                  : "#f93356",
                            }}
                            // onClick={handleCancelBooking}
                            onClick={() => setOptionBookingTrack(true)}
                          >
                            Modify Booking
                          </button>
                        )}
                        <Modal
                          onClose={() => setOptionBookingTrack(false)}
                          isOpen={optionBookingTrack}
                        >
                          <ModalHeader>Modify Booking</ModalHeader>
                          <ModalBody>
                            <div>
                              Would you like to reschedule your booking to
                              another date and time?
                            </div>
                            &nbsp;
                            <div className="modifyBookingPopUp">
                              <div onClick={handleCancelBooking}>
                                Cancel Booking
                              </div>
                              <div
                                onClick={() => setShowReschedulePopUp(true)}
                                className="reschedulePopUp"
                              >
                                Reschedule
                              </div>
                            </div>
                          </ModalBody>
                          &nbsp;
                        </Modal>

                        <Modal
                          onClose={handleCancelModal}
                          isOpen={isCancelModal}
                        >
                          <FocusOnce>
                            <ModalHeader>Cancel Booking?</ModalHeader>
                          </FocusOnce>
                          <ModalBody>
                            <div style={errMsgStyle}>{showErrMsgCancel}</div>
                            <div className="RadioGroupBlock">
                              {mapData.length === 0 ? null : (
                                <>
                                  {mapData.cancellation_reasons.map(
                                    (elem, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{ width: "40%" }}
                                        >
                                          <RadioGroup
                                            value={value}
                                            onChange={handleCancelBookingWay}
                                            name="CancelOrder"
                                            align={ALIGN.vertical}
                                          >
                                            <Radio
                                              value={elem.slug}
                                              overrides={radioOverrides}
                                            >
                                              <div style={RadioOption}>
                                                {elem.title}
                                              </div>
                                            </Radio>
                                          </RadioGroup>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <ModalButton
                              kind="tertiary"
                              onClick={handleCancelModal}
                            >
                              Cancel
                            </ModalButton>
                            {showCancelLoader ? (
                              <ModalButton
                                autoFocus
                                style={{
                                  backgroundColor:
                                    myGateBookingID &&
                                    (appCarWashTrackSrc === "wash" ||
                                      appCarWashTrackSrc === "driver" ||
                                      appCarWashTrackSrc === "ev")
                                      ? "#127e40"
                                      : "#f93356",
                                }}
                              >
                                <LoadingSpinner />
                              </ModalButton>
                            ) : mapData.is_wash_booking === true ? (
                              <ModalButton
                                autoFocus
                                onClick={handleCancelWashApiCall}
                                style={{
                                  backgroundColor:
                                    myGateBookingID &&
                                    (appCarWashTrackSrc === "wash" ||
                                      appCarWashTrackSrc === "driver" ||
                                      appCarWashTrackSrc === "ev")
                                      ? "#127e40"
                                      : "#f93356",
                                }}
                              >
                                Confirm
                              </ModalButton>
                            ) : (
                              <ModalButton
                                autoFocus
                                onClick={handleCancelApiCall}
                                style={{
                                  backgroundColor:
                                    myGateBookingID &&
                                    (appCarWashTrackSrc === "wash" ||
                                      appCarWashTrackSrc === "driver" ||
                                      appCarWashTrackSrc === "ev")
                                      ? "#127e40"
                                      : "#f93356",
                                }}
                              >
                                Confirm
                              </ModalButton>
                            )}
                            {/* <ModalButton autoFocus onClick={handleCancelApiCall}>
                         Confirm
                       </ModalButton> */}
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  )}
                </>
                <div>
                  <>
                    {mapData.booking_details.booking_type_display ===
                    "Essential Pressure Wash" ? (
                      <CarWashVideo
                        vidLink={
                          "https://apps.driveu.in/static/CarWashVideo.mp4"
                        }
                        ImageLink={
                          "https://ik.imagekit.io/driveu/Car_Wash/1080p_Video_Placeholder_hu1YIPvRu.png"
                        }
                      />
                    ) : mapData.booking_details.booking_type_display ===
                      "Essential Eco Wash" ? (
                      <CarWashVideo
                        vidLink={
                          "https://apps.driveu.in/static/waterlessvideo.mp4"
                        }
                        ImageLink={
                          "https://ik.imagekit.io/driveu/Home_Screen/FallBack_-_Waterless_Lo8pCyyVn.png"
                        }
                      />
                    ) : null}
                  </>
                </div>
                <>
                  {mapData.length === 0 ? null : mapData.is_wash_booking ===
                    true ? (
                    <>
                      {Object.entries(DriverFAQs.FAQsData[0].washfaqs)
                        .filter(
                          (el) =>
                            el[0] === mapData.booking_details.booking_status
                        )
                        .map((elem, index) => {
                          return (
                            <Fragment key={index}>
                              <h4>HELP AND SUPPORT</h4>
                              <div className="supportContainer">
                                {elem[1].map((quesData) => {
                                  return (
                                    <FAQsComponent
                                      key={quesData.id}
                                      id={quesData.id}
                                      quesData={quesData}
                                      mapData={mapData}
                                    />
                                  );
                                })}
                              </div>
                            </Fragment>
                          );
                        })}
                    </>
                  ) : mapData.booking_details.booking_type === "ev_charging" ? (
                    <>
                      {Object.entries(DriverFAQs.FAQsData[0].ev_charging)
                        .filter(
                          (el) =>
                            el[0] === mapData.booking_details.booking_status
                        )
                        .map((elem, index) => {
                          return (
                            <Fragment key={index}>
                              <h4>HELP AND SUPPORT</h4>
                              <div className="supportContainer">
                                {elem[1].map((quesData) => {
                                  return (
                                    <FAQsComponent
                                      key={quesData.id}
                                      id={quesData.id}
                                      quesData={quesData}
                                      mapData={mapData}
                                    />
                                  );
                                })}
                              </div>
                            </Fragment>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {Object.entries(DriverFAQs.FAQsData[0].helpfaqs)
                        .filter(
                          (el) =>
                            el[0] === mapData.booking_details.booking_status
                        )
                        .map((elem, index) => {
                          return (
                            <Fragment key={index}>
                              <h4>HELP AND SUPPORT</h4>
                              <div className="supportContainer">
                                {elem[1].map((quesData) => {
                                  return (
                                    <FAQsComponent
                                      key={quesData.id}
                                      id={quesData.id}
                                      quesData={quesData}
                                      mapData={mapData}
                                    />
                                  );
                                })}
                              </div>
                            </Fragment>
                          );
                        })}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        )}
      </>

      <>
        {mapData.length === 0 ? null : showMyGateDriverDetailsPopUp &&
          mapData.is_wash_booking === false &&
          mapData.booking_details.booking_status === "done" ? (
          <MyGateDriverDetails mapData={mapData} />
        ) : null}
        {showPaymentDetails ? <MyGatePaymentDetails mapData={mapData} /> : null}
        {mapData.length === 0 ? null : showMyGateDriverDetailsPopUp &&
          mapData.is_wash_booking === false &&
          mapData.booking_details.booking_status === "done" ? (
          <div className="overlay" />
        ) : null}
        {myGateStatusLoader ? <div className="overlayTrack" /> : ""}
        {showReschedulePopUp ? (
          <RescheduleBlock
            showReschedulePopUp={showReschedulePopUp}
            setShowReschedulePopUp={setShowReschedulePopUp}
            setOptionBooking={setOptionBookingTrack}
            showHistory={handleFetchBookingDetails}
            myGateBookingID={myGateBookingID}
            mapData={mapData}
          />
        ) : null}
      </>
    </React.Fragment>
  );
}

export default TrackingPage;
