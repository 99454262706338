import React, { useState, useEffect, useRef } from "react";
import "./RoundWayBooking.css";
import RoundWayInput from "./RoundWayInput/RoundWayInput";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Button } from "baseui/button";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FocusOnce,
  ModalFooter,
} from "baseui/modal";
import { TimePicker } from "baseui/timepicker";
import { MdInfoOutline, MdKeyboardArrowRight } from "react-icons/md";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { useContext } from "react";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import axios from "axios";
import { useCookies } from "react-cookie";
import TwoWayEstimate from "../OneWayBooking/FairEstimate/TwoWayEstimate";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import { DownloadContext } from "../../context/DownloadContextProvider";
import InsuranceConfirm from "../InsuranceConfirm/InsuranceConfirm";
import { Select } from "baseui/select";
import useBookDriverApi from "../../CustomHooks/useBookDriverApi";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import ConsentPopUp from "../../MyGate/ConsentBox/ConsentPopUp";
import clevertap from "clevertap-web-sdk";
import TermConditionNew from "../../parts/Term&Condition/TermConditionNew/TermConditionNew";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { couponVerifyClickedObj } from "../../utils/EventObject";
import { AiOutlinePercentage } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import useWindowSize from "../../CustomHooks/useWindowSize";
import useGetRoundTripData from "../../CustomHooks/useGetRoundTripData";
import PromoBottomSheet from "../PromoBottomSheet/PromoBottomSheet";
import PromoPopUpSheet from "../PromoPopUpSheet/PromoPopUpSheet";
import { FaCaretDown } from "react-icons/fa";

class ModalStateContainer extends React.Component {
  state = {
    isConfirmationOpen: false,
  };
  toggleConfirm = (open = !this.state.isConfirmationOpen, cb = () => {}) => {
    this.setState({ isConfirmationOpen: open }, cb);
  };
  render() {
    return this.props.children({
      isConfirmationOpen: this.state.isConfirmationOpen,
      toggleConfirm: this.toggleConfirm,
    });
  }
}

function RoundWayBooking() {
  const checkRef = useRef(null);
  const CarTypeRef = useRef(null);
  const whenTypeRef = useRef(null);
  const [couponCodeData, setCouponCodeData] = useState([]);
  const [couponCodeToggle, setCouponCodeToggle] = useState(false);
  const { handleBookDriverBookingRoundTrip } = useBookDriverApi();
  const { windowWidth } = useWindowSize();
  const { handleGetAllPromoCode, roundWayCouponData } = useGetRoundTripData();
  const [couponCodeLength, setCouponCodeLength] = useState(false);
  const {
    premium,
    gst,
    showCarType,
    setShowCarType,
    showCarModel,
    setShowCarModel,
    roundWayCarTypeString,
    setRoundWayCarTypeString,
    handleGetValueToolTip,
    promoBottomSheet,
    setPromoBottomSheet,
    promoPopUpSheet,
    setPromoPopUpSheet,
    couponLatLngObj,
  } = React.useContext(BookingFlowParaContext);
  const { setShowPopUp, showPopUp, setPreviousChoice } = useContext(
    DownloadContext
  );
  const {
    myGateToken,
    setMyGatePopUp,
    consentFlag,
    myGateTokenData,
  } = useContext(ApplicationContext);
  // All state define here ---- starting
  const [cookiesHireDrivers] = useCookies();
  const [dateArr, setDateArr] = useState([]);
  const [showPackage, setShowPackage] = useState(false);
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showDate, setShowDate] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [defaultDate, setDefaultDate] = useState("");
  // const [dateTimeString, setDateTimeString] = useState("");
  const [coupanText, setCoupanText] = useState("");
  const [coupanSucessMessage, setCoupanSucessMessage] = useState("");
  const [showCoupanMessage, setShowCoupanMessage] = useState(false);
  const [showErrorColor, setShowErrorColor] = useState(false);
  const [isTwoWayFareClose, setIsTwoWayFareClose] = useState(false);
  const [twoWaySpinner, setTwoWaySpinner] = useState(false);
  const { isAuthenticated } = useContext(BookingFlowParaContext);
  const [currTime, setCurrTime] = useState("");
  // const [showBookLoaderRound, setShowBookLoaderRound] = useState(false);
  const [dateTimeValue] = useState({
    CurrentYear: "",
    CurrentDate: "",
    currentMonth: "",
  });
  const [roundWayFare, setRoundWayFare] = useState("");
  const {
    sendingRoundWayData,
    toggleRoundWayFareEstimate,
    roundWayToggle,
    setFareEstimateTwoWay,
    twoWayChecked,
    setTwoWayChecked,
    isOpenIns,
    setIsOpenIns,
    isErrMsgBooking,
    setIsErrMsgBooking,
    showRoundWayFare,
    setShowRoundWayFare,
    activeKey,
    showBookLoaderRound,
    setDateTimeAllWay,
    fareParams,
    // ETA
    showRoundETA,
    etaRoundWayTime,
    showRoundWhen,
    setShowRoundWhen,
    setRoundWayETATimeString,
    setEventFareEstimate,
    setFareExtraInfo,
    setCancelFarePopOver,
  } = useContext(BookingFlowDataContext);
  const [packageValue, setPackageValue] = useState("Package");

  const PackageArray = ["2", "4", "6", "8", "12"];
  const newYearArray = ["4"];
  const [showOnlyOneHoursArray, setShowOnlyOneHoursArray] = useState(false);

  const [compareBaseTime, setCompareBaseTime] = useState("");
  const [showMinTime, setShowMinTime] = useState(false);

  //=======Msilparams Object start here ========

    const {msilTokenData,setMsilTokenData} = useContext(ApplicationContext);
  
    //=======Msilparams Object end here ========
  
  useEffect(() => {
    if (new Date().getDate() === compareBaseTime || compareBaseTime === "") {
      setShowMinTime(false);
    } else {
      setShowMinTime(true);
    }
  }, [compareBaseTime]);
  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  const currentTime = new Date().getTime();
  const date = new Date(currentTime + 1.12 * 60 * 60 * 1000);
  // const [val, setVal] = React.useState(
  //   new Date(Math.round(date.getTime() / ms) * ms)
  // );
  let currentPickUpDate = new Date(Math.round(date.getTime() / ms) * ms);
  const [val, setVal] = useState(currentPickUpDate);
  let new_Date = new Date();
  let DATE = new_Date.getDate();
  let YEAR = new_Date.getFullYear();
  let MONTH = new_Date.getMonth() + 1;
  let StartingDate = DATE.toString().length === 1 ? `0${DATE}` : DATE;
  let StartingMONTH = MONTH.toString().length === 1 ? `0${MONTH}` : MONTH;
  let StartingMinute =
    val.getMinutes() === 0 ? `0${val.getMinutes()}` : val.getMinutes();
  const [dateTimeString, setDateTimeString] = useState(
    `${StartingDate}/${StartingMONTH}/${YEAR} ${val.getHours()}:${StartingMinute}`
  );
  useEffect(() => {
    if (showCarModel[0].label === "SUV") {
      showCarModel[0].label = "SUV";
    } else if (showCarModel[0].label === "Luxury") {
      showCarModel[0].label = "Luxury";
    }
    setRoundWayCarTypeString(
      `${showCarType[0].label} - ${showCarModel[0].label}`
    );
    handleGetValueToolTip();
  }, [showCarType, showCarModel]);
  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handleDate = () => {
    setShowDate(!showDate);
  };
  const handleDateString = (currentYear, currentMonth, currentDate) => (e) => {
    setCurrTime(currentDate);
    setCompareBaseTime(currentDate);
    setShowDate(false);
    dateTimeValue.CurrentYear = currentYear;
    dateTimeValue.CurrentDate =
      currentDate.toString().length === 1 ? `0${currentDate}` : currentDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(currentMonth) + 1).toString().length === 1
        ? `0${monthDay.indexOf(currentMonth) + 1}`
        : monthDay.indexOf(currentMonth) + 1;
    setDefaultDate(e.target.innerText);
  };
  useEffect(() => {
    var arr = [];
    for (var i = 0; i < 5; i++) {
      var date = new Date();
      var newDate = date.setDate(date.getDate() + i);
      let show = new Date(newDate);
      let onlyDate = show.getDate();
      let year = show.getFullYear();
      var month = monthDay[show.getMonth()];
      var week = weekDay[show.getDay()];
      if (onlyDate.toString().length === 2) {
        arr.push({ week, onlyDate, month, year });
      } else {
        onlyDate = "0" + onlyDate;
        arr.push({ week, onlyDate, month, year });
      }
    }
    let string = arr[0].week + " " + arr[0].onlyDate + " " + arr[0].month;
    setDateArr(arr);
    setDefaultDate(string);
    dateTimeValue.CurrentYear = arr[0].year;
    dateTimeValue.CurrentDate =
      arr[0].onlyDate.toString().length === 1
        ? `0${arr[0].onlyDate}`
        : arr[0].onlyDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(arr[0].month) + 1).toString().length === 1
        ? `0${monthDay.indexOf(arr[0].month) + 1}`
        : monthDay.indexOf(arr[0].month) + 1;
  }, []);
  useEffect(() => {
    if (val.getMinutes() === 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:0${val.getMinutes()}`
      );
    } else if (val.getMinutes() !== 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:${val.getMinutes()}`
      );
    }
    // setRoundWayETATimeString
    if (showRoundETA && showRoundWhen[0].label.includes("Now")) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() + etaRoundWayTime);
      let hr = dt.getHours();
      let min = dt.getMinutes();

      setRoundWayETATimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${dateTimeValue.CurrentYear} ${hr}:${min}`
      );
    }
  }, [defaultDate, val, etaRoundWayTime]);

  const showPackageHours = () => {
    setShowPackage(!showPackage);
  };
  const handlePackageHours = (num) => (e) => {
    sendingRoundWayData.booking_type = `${num}hr`;
    setPackageValue(num);
    setShowPackage(false);
  };
  const roundWayCouponApi = () => {
    sendingRoundWayData.user_id = cookiesHireDrivers.userid;
    // sendingRoundWayData.promo_code = coupanText;
    if (coupanText === "") {
      setCoupanSucessMessage("Coupon Code Required");
      setShowCoupanMessage(true);
      setTimeout(() => {
        setCoupanSucessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (coupanText.length > 16) {
      setCoupanSucessMessage("Character limit exceeded");
      setShowCoupanMessage(true);
      setTimeout(() => {
        setCoupanSucessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (!cookiesHireDrivers.userid === false) {
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url:
          !myGateToken === true
            ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${sendingRoundWayData.city ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${sendingRoundWayData.booking_type ||
                "2hr"}&format=json&promo_code=${coupanText}&src=website&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeString}`
            : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${sendingRoundWayData.city ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${sendingRoundWayData.booking_type ||
                "2hr"}&format=json&promo_code=${coupanText}&src=my_gate&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeString}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            couponVerifyClickedObj.coupon_code = coupanText;
            couponVerifyClickedObj.status = "valid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = "N/A";
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
            setCouponCodeToggle(true);
            setShowErrorColor(true);
            setCouponCodeData(data);
            setPromoBottomSheet(false);
            setPromoPopUpSheet(false);
            setCoupanSucessMessage(data.message);
            sendingRoundWayData.promo_code = coupanText;
            setShowCoupanMessage(true);
          } else if (data.status === "error") {
            couponVerifyClickedObj.coupon_code = coupanText;
            couponVerifyClickedObj.status = "invalid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = data.message;
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
            setShowErrorColor(false);
            setCoupanSucessMessage(data.message);
            setTimeout(() => {
              setCoupanSucessMessage("");
            }, 3000);
            setShowCoupanMessage(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const fetchRoundWayFareApi = () => {
    fareParams.booking_type = `${packageValue}hr`;
    fareParams.num_hours = `${packageValue}`;
    fareParams.user_id = sendingRoundWayData.user_id;
    fareParams.car_type = roundWayCarTypeString;
    fareParams.is_insured = +twoWayChecked;
    fareParams.pickup_datetime = dateTimeString;

    sendingRoundWayData.booking_type = `${packageValue}hr`;
    sendingRoundWayData.pickup_datetime = dateTimeString;
    sendingRoundWayData.estimated_trip_hours = packageValue;

    setTwoWaySpinner(true);
    // axios({
    //   method: "GET",
    //   baseURL: BASE_URL_HULK,
    //   url: `${BASE_URL_HULK}/pricing/package-details/?city_id=${sendingRoundWayData.city}&service_type=classic&pickup_datetime=${dateTimeString}&user_id=${cookiesHireDrivers.userid}`,
    //   withCredentials: false,
    //   headers: {
    //     "Content-Type": `application/json`,
    //   },
    // })
    //   .then(({ data }) => {
    //     if (data.status === "success") {
    //       fetchingFare(data.packages);
    //       setTwoWaySpinner(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //     setTwoWaySpinner(false);
    //   });

    const res = axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/fare-estimate-v2/`,
      withCredentials: false,
      params: fareParams,
      headers: {
        "Content-Type": `application/json`,
      },
    });
    res
      .then(({ data }) => {
        fetchingFareNew(data.service_types);
        setTwoWaySpinner(false);
      })
      .catch((err) => {
        console.log(err.message);
        setTwoWaySpinner(false);
      });
  };
  useEffect(() => {
    setDateTimeAllWay(dateTimeString);
    if (
      toggleRoundWayFareEstimate &&
      packageValue !== "Package" &&
      roundWayToggle
    ) {
      fetchRoundWayFareApi();
    }
  }, [roundWayToggle, packageValue, dateTimeString, twoWayChecked]);

  // const fetchingFare = (data) => {
  //   data.forEach((elem) => {
  //     if (elem.slug === sendingRoundWayData.booking_type) {
  //       setFareEstimateTwoWay(elem.fare_details);
  //       setRoundWayFare(elem.fare);
  //       setEventFareEstimate(elem.fare);
  //       setShowRoundWayFare(true);
  //     }
  //   });
  // };

  const fetchingFareNew = (data) => {
    const driveData = data.find(
      (service) => service.service_type === "classic"
    );

    setFareExtraInfo(driveData.extra_info);
    setFareEstimateTwoWay(driveData.fare_details);
    setRoundWayFare(driveData.total_fare);
    setEventFareEstimate(driveData.total_fare);
    setCancelFarePopOver(driveData.cancel_info);
    setShowRoundWayFare(true);
  };

  useEffect(() => {
    // When REf start
    let controlWhen = whenTypeRef.current.children[0].children[0];
    controlWhen.style.borderStyle = "none";
    controlWhen.style.borderBottom = "0.2px solid #888";
    controlWhen.style.backgroundColor = "white";
    controlWhen.style.color = "#666";
    // When Ref end
    var control =
      checkRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    control.setAttribute("inputmode", "none");
    var controlBorder = checkRef.current.children[0].children[0];
    controlBorder.style.borderStyle = "none";
    controlBorder.style.borderBottom = "0.2px solid #888";
    const carTypeControl = CarTypeRef.current.children[0].children[0];
    const carTypeControlKeyBoard =
      CarTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    carTypeControlKeyBoard.setAttribute("inputmode", "none");
    carTypeControl.style.borderStyle = "none";
    carTypeControl.style.color = "#666";
    carTypeControl.style.backgroundColor = "white";
    carTypeControl.style.borderBottom = "0.2px solid #888";
    const carModelControl = CarTypeRef.current.children[1].children[0];
    const carModelControlKeyBoard =
      CarTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    carModelControlKeyBoard.setAttribute("inputmode", "none");
    carModelControl.style.borderStyle = "none";
    carModelControl.style.color = "#666";
    carModelControl.style.backgroundColor = "white";
    carModelControl.style.borderBottom = "0.2px solid #888";

    const whenTypeControl =
      whenTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    whenTypeControl.setAttribute("inputmode", "none");
  }, []);

  const handleCreateRoundWayBooking = () => {
    if (sendingRoundWayData.pickup_address === "") {
      setIsErrMsgBooking("Enter pickup Address");
      setTimeout(() => {
        setIsErrMsgBooking("");
      }, 1000);
    } else if (packageValue === "Package") {
      setIsErrMsgBooking("Select Package Hours");
      setTimeout(() => {
        setIsErrMsgBooking("");
      }, 1000);
    } else {
      sendingRoundWayData.car_type = roundWayCarTypeString;
      sendingRoundWayData.my_gate_session_id = myGateToken;
      if (twoWayChecked === false) {
        setIsOpenIns(true);
      } else {
        sendingRoundWayData.is_insured = twoWayChecked;

        if (myGateToken && consentFlag === false) {
          setMyGatePopUp(true);
          setPreviousChoice("MyGateRequestBookingRoundWay");
        } else {
          if (isAuthenticated === false && !myGateToken === true) {
            setPreviousChoice("RequestBookingRoundWay");
            setShowPopUp(true);
          } else {
            handleBookDriverBookingRoundTrip();
          }
        }
      }
    }
  };

  const handleOpenSecureToolTip = () => {
    const duSecureInfoViewed = {
      client: myGateToken ? "mygate" : "website",
    };
    clevertap.event.push("du_secure_info_viewed", duSecureInfoViewed);
    setOpen((s) => !s);
  };

  useEffect(() => {
    setTwoWaySpinner(false);
    setShowRoundWayFare(false);
  }, [activeKey]);

  useEffect(() => {
    if (val.getDate() === +currTime) {
      setVal(currentPickUpDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    if (
      (dateTimeValue.CurrentDate === 31 &&
        dateTimeValue.currentMonth === 12 &&
        val.toLocaleTimeString() >= "18:00:00") ||
      (dateTimeValue.CurrentDate === "01" &&
        dateTimeValue.currentMonth === "01" &&
        val.toLocaleTimeString() <= "06:00:00")
    ) {
      setShowOnlyOneHoursArray(true);
      setPackageValue(4);
    } else {
      setShowOnlyOneHoursArray(false);
    }
  }, [defaultDate, val]);

  const handleChangeETARoundTrip = (params) => {
    setShowRoundWhen(params.value);
    const demandTypeObj = {
      demand: "",
      client: myGateToken ? "mygate" : "website",
    };
    if (params.value[0].label.includes("Now")) {
      demandTypeObj.demand = "now";
    } else {
      demandTypeObj.demand = "later";
    }
    clevertap.event.push("demand_type_updated", demandTypeObj);
  };

  const handleChangeTwoWayChecked = (e) => {
    setTwoWayChecked(e.target.checked);
    const duSecureAdjustObj = {
      action: "",
      client: myGateToken ? "mygate" : "website",
    };
    if (e.target.checked === true) {
      duSecureAdjustObj.action = "applied";
    } else if (e.target.checked === false) {
      duSecureAdjustObj.action = "removed";
    }
    clevertap.event.push("du_secure_adjusted", duSecureAdjustObj);
  };

  const handleApplyPromocode = () => {
    sendingRoundWayData.pickup_datetime = dateTimeString;
    if (windowWidth > 768) {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoPopUpSheet(true);
      }
    } else {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoBottomSheet(true);
      }
    }
  };
  const handleFillPromoCode = (promoCode) => {
    setCoupanText(promoCode);
    setCouponCodeLength(true);

    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url:
        !myGateToken === true
          ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${sendingRoundWayData.city ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${sendingRoundWayData.booking_type ||
              "2hr"}&format=json&promo_code=${promoCode}&src=website&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeString}`
          : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${sendingRoundWayData.city ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${sendingRoundWayData.booking_type ||
              "2hr"}&format=json&promo_code=${promoCode}&src=my_gate&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeString}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setCouponCodeData(data);
          setShowErrorColor(true);
          setCoupanSucessMessage(data.message);
          sendingRoundWayData.promo_code = promoCode;
          setCouponCodeToggle(true);
          setShowCoupanMessage(true);
          setPromoBottomSheet(false);
          setPromoPopUpSheet(false);
        } else if (data.status === "error") {
          setCouponCodeToggle(false);
          setShowErrorColor(false);
          setCoupanSucessMessage(data.message);
          setTimeout(() => {
            setCoupanSucessMessage("");
          }, 3000);
          setShowCoupanMessage(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleRemoveSuccessCoupon = () => {
    setCouponCodeToggle(false);
    setCoupanSucessMessage("");
    setCoupanText("");
    setCouponCodeLength(false);
  };
  const handleGetOtherOffers = () => {
    if (windowWidth > 768) {
      setPromoPopUpSheet(true);
    } else {
      setPromoBottomSheet(true);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="roundWay">
          <div
            style={{ marginTop: "-1.5rem" }}
            className="inputBox roundWayInputBox"
          >
            <div style={{ color: "#888" }} className="fontMedium">
              Choose Location
            </div>
            <RoundWayInput
              handleRemoveSuccessCoupon={handleRemoveSuccessCoupon}
            />
          </div>

          <div>
            <div className="oneWayBottomDiv">
              {/* ETA Div */}
              {/* start */}
              <div
                style={{ display: showRoundETA === false ? "none" : "" }}
                className="CarTypeBlock"
              >
                <div className="fontMedium">When is driver needed ? </div>
                <div className="CarType" ref={whenTypeRef}>
                  <Select
                    clearable={false}
                    options={[
                      {
                        label: showRoundETA
                          ? `Now - in ${etaRoundWayTime} min`
                          : "Now",
                        id: "#F0F8FF",
                      },
                      { label: "Schedule for later", id: "#FAEBD7" },
                    ]}
                    placeholder="When you need driver"
                    value={showRoundWhen}
                    searchable="false"
                    onChange={handleChangeETARoundTrip}
                  />
                </div>
              </div>
              {/* end */}
              {/* package Div */}
              <div className="RoundWayPkgBlock">
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Select Package
                </div>
                <div onClick={showPackageHours} className="packageDate">
                  <div>
                    <input
                      placeholder="Select Package"
                      className="packageDateInput"
                      type="none"
                      value={`${packageValue} Hours`}
                      readOnly
                      required
                    />
                  </div>
                  <div className="packageinputImage">
                    <FaCaretDown size={15} style={{ width: "0.7em"}} />

                  </div>
                </div>
                <div
                  style={{ display: showPackage ? "" : "none" }}
                  className="packageDiv"
                >
                  {showOnlyOneHoursArray
                    ? newYearArray.map((elem, index) => (
                        <div
                          className="packageOption"
                          onClick={handlePackageHours(elem)}
                          style={{
                            display: showPackage ? "" : "none",
                          }}
                          key={index}
                        >
                          {elem} Hours
                        </div>
                      ))
                    : PackageArray.map((elem, index) => (
                        <div
                          className="packageOption"
                          onClick={handlePackageHours(elem)}
                          style={{
                            display: showPackage ? "" : "none",
                          }}
                          key={index}
                        >
                          {elem} Hours
                        </div>
                      ))}
                </div>
              </div>
              <div
                style={{
                  display:
                    showRoundETA === false
                      ? ""
                      : showRoundWhen.length !== 0 &&
                        showRoundWhen[0].label.includes("Now")
                      ? "none"
                      : "",
                  marginTop: "0.8rem",
                }}
              >
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Date & Time
                </div>
                <div className="DataAndTimeInput">
                  <div className="Date" onClick={handleDate}>
                    <div style={{ width: "70%" }}>
                      <input
                        placeholder="Select Date"
                        className="dateInput"
                        type="none"
                        value={defaultDate}
                        readOnly
                        required
                      />
                    </div>
                    <div>
                      <img
                        height="5"
                        width="10px"
                        src="https://ik.imagekit.io/bksgfrlfg/DropDown-Icon_MPnhInvgo.png"
                        alt="arrow"
                      />
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setShowDate(false);
                    }}
                    className="Time"
                    ref={checkRef}
                  >
                    {val.getDate() === +currTime ? (
                      <TimePicker
                        value={val}
                        onChange={(date) => setVal(date)}
                        minTime={new Date(Math.round(date.getTime() / ms) * ms)}
                        style={{ backgroundColor: "white" }}
                      />
                    ) : (
                      <TimePicker
                        value={val}
                        onChange={(date) => setVal(date)}
                        minTime={
                          showMinTime
                            ? ""
                            : new Date(Math.round(date.getTime() / ms) * ms)
                        }
                        style={{ backgroundColor: "white" }}
                      />
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "0.5rem" }} className="dateDiv">
                  {dateArr.map((elem, index) => (
                    <div
                      className="dateOption"
                      onClick={handleDateString(
                        elem.year,
                        elem.month,
                        elem.onlyDate
                      )}
                      style={{
                        display: showDate ? "" : "none",
                      }}
                      key={index}
                    >
                      {elem.week} {elem.onlyDate} {elem.month}
                    </div>
                  ))}
                </div>
              </div>
              <div
                onClick={() => {
                  setShowDate(false);
                }}
                className="RoundWayCarType"
              >
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Car Type
                </div>
                <div className="CarType" ref={CarTypeRef}>
                  <Select
                    clearable={false}
                    options={[
                      { label: "Manual", id: "#F0F8FF" },
                      { label: "Automatic", id: "#FAEBD7" },
                    ]}
                    value={showCarType}
                    searchable={false}
                    onChange={(params) => setShowCarType(params.value)}
                  />
                  <Select
                    clearable={false}
                    options={[
                      { label: "Hatchback", id: "#F0F8FF" },
                      { label: "Sedan", id: "#FAEBD7" },
                      { label: "SUV", id: "#00FFFF" },
                      { label: "Luxury", id: "#7FFFD4" },
                    ]}
                    value={showCarModel}
                    searchable={false}
                    onChange={(params) => setShowCarModel(params.value)}
                  />
                </div>
              </div>
              {couponCodeToggle === false ? (
                <div
                  onClick={handleApplyPromocode}
                  style={{ marginTop: "0.75rem" }}
                >
                  <div className="newPromoContainer">
                    <AiOutlinePercentage className="newPromoDiscountImage" />
                    <div className="fontMedium couponText">
                      <span>Apply Coupon</span>
                      <MdKeyboardArrowRight
                        style={{ color: "#191C19", fontSize: "1rem" }}
                      />
                    </div>
                  </div>

                  {myGateToken ? (
                    <div
                      style={{
                        display:
                          myGateTokenData.length === 0 ? "none" : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  ) : (
                    <div
                      style={{
                        display:
                          !cookiesHireDrivers.userid === true ||
                          cookiesHireDrivers.userid === "undefined"
                            ? "none"
                            : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="newPromoSuccessContainer">
                    <div className="successPromoContainer">
                      <div className="successPromoF1">
                        <div className="PromoF1">
                          <div className="PromoF11">
                            <FaCheckCircle />
                          </div>
                          <div className="PromoF12">{coupanText} Applied</div>
                        </div>
                        <div className="PromoF2">
                          {couponCodeData.title === ""
                            ? "Code Applied"
                            : couponCodeData.title}
                        </div>
                      </div>
                      <div
                        className="successPromoF2"
                        onClick={handleRemoveSuccessCoupon}
                      >
                        Remove
                      </div>
                    </div>
                    <hr className="successLineL1" />
                    <div
                      className="otherOffersO1"
                      onClick={handleGetOtherOffers}
                    >
                      Other Offers
                    </div>
                  </div>
                </>
              )}
              <div
                style={{
                  color: "#888",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginBottom: "0.4rem",
                  marginTop: "0.8rem",
                  fontFamily: "Circular-Std-Book",
                }}
              >
                SECURE YOUR BOOKING &nbsp;
                <ModalStateContainer>
                  {({ isConfirmationOpen, toggleConfirm }) => (
                    <React.Fragment>
                      <MdInfoOutline
                        style={{
                          fontSize: "14px",
                          color :msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                          cursor: "pointer",
                        }}
                        onClick={handleOpenSecureToolTip}
                      />

                      <Modal onClose={() => setOpen(false)} isOpen={isOpen}>
                        <FocusOnce>
                          <ModalHeader
                            style={{
                              fontFamily: "Circular-Std-Bold",
                              color: "#666",
                            }}
                          >
                            DriveU Secure
                          </ModalHeader>
                        </FocusOnce>
                        <ModalBody>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                            }}
                          >
                            <h4 style={{ marginBottom: "0.5rem" }}>
                              Vehicle Damage Protection Plan
                            </h4>
                            <table>
                              <tbody className="insTableBody">
                                <tr>
                                  <td>DU Secure Fee&nbsp;</td>
                                  <td>&nbsp;₹{`${premium}`}/-</td>
                                </tr>
                                <tr>
                                  <td>GST&nbsp;</td>
                                  <td>&nbsp;₹{`${gst}`}/-</td>
                                </tr>
                                <tr>
                                  <td>Total&nbsp;</td>
                                  <td>&nbsp;₹{`${+premium + +gst}`}/-</td>
                                </tr>
                              </tbody>
                            </table>
                          </center>
                          <br />
                          <ol
                            style={{
                              fontFamily: "Circular-Std-Book",
                              marginLeft: "24px",
                            }}
                          >
                            <li>Vehicle damages up to ₹15,000*</li>
                            <li>
                              Claim to be raised within 3 days of trip end
                            </li>
                          </ol>
                        </ModalBody>
                        <ModalFooter>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                              color: "#666",
                              marginBottom: "16px",
                            }}
                          >
                            {/* <h4>Powered By</h4> */}
                            {/* <p>Acko General Insurance LTD</p> */}
                            {myGateToken ? (
                              <div
                                style={{
                                  opacity: "0.7",
                                  color :msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                                }}
                                onClick={() => toggleConfirm(true)}
                              >
                                View Terms and Conditions
                              </div>
                            ) : (
                              <Link
                                to="/tnc"
                                style={{
                                  opacity: "0.7",
                                  cursor : "pointer",
                                  color: myGateToken ? " #f93356" : "#127e40",
                                }}
                              >
                                View Terms and Conditions
                              </Link>
                            )}
                          </center>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        onClose={() => toggleConfirm(false)}
                        isOpen={isConfirmationOpen}
                      >
                        <ModalHeader>DRIVEU TERMS AND CONDITIONS</ModalHeader>
                        <ModalBody>
                          <div
                            style={{
                              width: "100%",
                              height: "500px",
                              overflow: "scroll",
                            }}
                          >
                            <TermConditionNew />
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                  )}
                </ModalStateContainer>
              </div>
              <Checkbox
                checked={twoWayChecked}
                // onChange={(e) => setTwoWayChecked(e.target.checked)}
                onChange={handleChangeTwoWayChecked}
                labelPlacement={LABEL_PLACEMENT.right}
                overrides={{
                  Label: {
                    style: ({ $theme }) => ({
                      color: "#888",
                      fontSize: "12px",
                      fontWeight: "400px",
                      marginBottom: "0.5rem",
                      fontFamily: "Circular-Std-Book",
                      marginRight: "1rem",
                      lineHeight: "150%",
                    }),
                  },
                }}
              >
                Get vehicle damage protection plan for your car for ₹
                {`${+premium}`}+GST T&C apply*
              </Checkbox>
              <div
                style={{
                  display: showRoundWayFare ? "" : "none",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.5rem",
                    fontFamily: "Circular-Std-Book",
                  }}
                >
                  -----------
                  <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                    FARE ESTIMATE
                  </span>
                  -------------
                </div>
                {twoWaySpinner ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <div
                    style={{
                      color: "#0085b2",
                      fontSize: "30px",
                      fontWeight: "400",
                      marginBottom: "0.5rem",
                      textAlign: "center",
                      fontFamily: "Circular-Std-Book",
                    }}
                  >
                    ₹{roundWayFare}
                    <MdInfoOutline
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "gray",
                        paddingTop: "0.4rem",
                      }}
                      onClick={() => setIsTwoWayFareClose(true)}
                    />
                    {isTwoWayFareClose ? (
                      <TwoWayEstimate
                        isTwoWayFareClose={isTwoWayFareClose}
                        setIsTwoWayFareClose={setIsTwoWayFareClose}
                      />
                    ) : null}
                  </div>
                )}
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.2rem",
                    fontFamily: "Circular-Std-Book",
                    marginRight: "1rem",
                  }}
                >
                  This is just an estimate
                </div>
              </div>
            </div>
            <div
              style={{
                fontFamily: "Circular-Std-Book",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
              className="err-msg-text"
            >
              {isErrMsgBooking}
            </div>
            <br />
            {showBookLoaderRound ? (
              <Button
                onClick={handleCreateRoundWayBooking}
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor: myGateToken ? " #f93356" : "#127e40",
                  color: "#ffffff",
                  marginTop: "-1rem",
                  paddingLeft: "0rem !important",
                }}
                isLoading
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Circular-Std-Medium",
                  }}
                >
                  Request Driver
                </span>
              </Button>
            ) : (
              <Button
                onClick={handleCreateRoundWayBooking}
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                  color: "#ffffff",
                  paddingLeft: "0rem !important",
                  marginTop: "-1rem",
                }}
                id="RoundWay-Booking"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Circular-Std-Medium",
                  }}
                >
                  Request Driver
                </span>
              </Button>
            )}
          </div>
        </div>
      </form>

      <PromoBottomSheet
        promoBottomSheet={promoBottomSheet}
        setPromoBottomSheet={setPromoBottomSheet}
        setCoupanSucessMessage={setCoupanSucessMessage}
        coupanSucessMessage={coupanSucessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        checkingCouponApi={roundWayCouponApi}
        coupanText={coupanText}
        setCoupanText={setCoupanText}
        showCoupanMessage={showCoupanMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={roundWayCouponData}
      />
      <PromoPopUpSheet
        promoPopUpSheet={promoPopUpSheet}
        setPromoPopUpSheet={setPromoPopUpSheet}
        setCoupanSucessMessage={setCoupanSucessMessage}
        coupanSucessMessage={coupanSucessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        coupanText={coupanText}
        setCoupanText={setCoupanText}
        checkingCouponApi={roundWayCouponApi}
        showCoupanMessage={showCoupanMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={roundWayCouponData}
      />
      {isOpenIns ? <InsuranceConfirm /> : null}
      <ConsentPopUp couponCodeToggle={couponCodeToggle} />
      {showPopUp ? <div className="overlay" /> : null}
      {showPopUp ? <RegistrationForm className="form_number" /> : null}
    </>
  );
}
export default RoundWayBooking;
