import React from "react";
import "./TrackPage.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FocusOnce, Modal, ModalBody, ModalHeader } from "baseui/modal";
import LottieLoaderanim from "../Uis/LottieLoader/LottieLoaderanim";
import clevertap from "clevertap-web-sdk";
import { BookingFlowDataContext } from "../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../context/ApplicationContextProvider";
import { chatNowInitiatedObj } from "../utils/EventObject";
function FAQsComponent({ id, quesData, mapData }) {
  const [selected, setSelected] = React.useState(false);
  const [openFreshChat, setOpenFreshChat] = React.useState(false);
  const [isCancelModal, setIsCancelModal] = React.useState(false);
  const [chatNowLoader, setChatNowLoader] = React.useState(false);
  const { trackDriverScreen } = React.useContext(BookingFlowDataContext);
  const { myGateBookingID, appCarWashTrackSrc } = React.useContext(
    ApplicationContext
  );
  const handleSelected = () => {
    setSelected((p) => !p);
  };
  const handleFreshChat = () => {
    // console.log("handleFresh" ,mapData);
    console.log(appCarWashTrackSrc)
    chatNowInitiatedObj["Service Name"] =
      mapData.is_wash_booking === true ? "car_wash" : "driver_service";
    chatNowInitiatedObj.bookingid = mapData.booking_details.booking_id;
    chatNowInitiatedObj.booking_status = mapData.booking_details.booking_status;
    chatNowInitiatedObj.FAQ_Statement = quesData.question;
    chatNowInitiatedObj.source = trackDriverScreen;
    chatNowInitiatedObj.client = "mygate";
    clevertap.event.push("chat_now_initiated", chatNowInitiatedObj);
    setChatNowLoader(true);
    setTimeout(() => {
      setChatNowLoader(false);
    }, 2000);
    setOpenFreshChat(true);
    setIsCancelModal(true);
  };
  const handleCloseChatModal = () => {
    setIsCancelModal(false);
    setOpenFreshChat(false);
    setSelected(false);
  };
  return (
    <div id={id} onClick={handleSelected} className="supportWrapper">
      <div className="supportWrapperArrow">
        <div>{quesData.question}</div>
        <IoIosArrowUp
          style={{ display: selected ? "" : "none" }}
          className="FAQArrowColor"
        />
        <IoIosArrowDown
          style={{ display: selected ? "none" : "" }}
          className="FAQArrowColor"
        />
      </div>

      <div
        style={{
          display: selected ? "" : "none",
          fontWeight: "400",
          color: "#09081a",
          opacity: "0.6",
          marginTop: "5px",
          width: "100%",
        }}
      >
        <div>{quesData.answer}</div>
        {/* <a href="/mygate-driver-help" style={{ color: "#666" }}> */}
        <button
          className="chatNow"
          onClick={handleFreshChat}
          style={{
            color:
              mapData.length === 0
                ? null
                : myGateBookingID && (appCarWashTrackSrc==="wash" || appCarWashTrackSrc==="driver" || appCarWashTrackSrc ==="ev")
                ? "#127e40"
                : "#f93356",
          }}
        >
          Chat Now
        </button>
        {/* </a> */}

        {openFreshChat ? (
          <Modal
            onClose={handleCloseChatModal}
            isOpen={isCancelModal}
            overrides={{
              Dialog: {
                style: ({ $theme }) => ({
                  outline: "none",
                  height: "600px",
                  width: "350px",
                }),
              },
            }}
          >
            <FocusOnce>
              <ModalHeader>DriveU Help</ModalHeader>
            </FocusOnce>
            <ModalBody>
              {chatNowLoader === true ? (
                <LottieLoaderanim />
              ) : (
                <>
                  {myGateBookingID && (appCarWashTrackSrc==="wash") ? (
                    <iframe
                      src="/carwash-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>
                  ) : mapData.is_wash_booking === true ? (
                    <iframe
                      src="/mygate-wash-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>

                  ) : mapData.booking_details.booking_type === "ev_charging" ? (
                    <iframe
                      src="/ev-charging"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>
                  ) : (
                    <iframe
                      src="/mygate-driver-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>
                  )}
                  {/* {mapData.is_wash_booking === true ? (
                    <iframe
                      src="/mygate-wash-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>
                  ) : (
                    <iframe
                      src="/mygate-driver-help"
                      title="DriveU Help"
                      width="100%"
                      height="500px"
                    ></iframe>
                  )} */}
                </>
              )}
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    </div>
  );
}

export default FAQsComponent;
